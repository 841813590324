<template>
  <div class="nav-app align-items-center">
    <b-nav class="nav align-items-center mx-auto" justified>
      <b-link class="nav-link"><feather-icon icon="HomeIcon" size="21" /></b-link>
      <b-link class="nav-link"><feather-icon icon="CreditCardIcon" size="21" /></b-link>
      <b-link class="nav-link"><feather-icon icon="DollarSignIcon" size="21" /></b-link>
      <b-link class="nav-link"><feather-icon icon="BellIcon" size="21" /></b-link>
      <b-link class="nav-link"><feather-icon icon="MenuIcon" size="21" /></b-link>
    </b-nav>
  </div>
</template>

<script>
import {
  BNav, BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import { initialAbility } from '@/libs/acl/config'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNav,
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Reset ability
      this.$ability.update(initialAbility)

      // Redirect to login page
      this.$router.push({ name: 'auth-login' })
    },
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
}
</script>

<style>
#wrap {
  min-height: 100%;
}
#main {
  overflow:auto;
  padding-bottom:150px;
}
.nav-app {
  position: relative;
  margin-top: -150px;
  height: 150px;
  clear:both;
  padding-top:20px;
} 
</style>