<template>
  <vue-mobile-sized-view
    :background-color="theme.backgroundColor"
    :screen-background-color="theme.screenBackgroundColor"
    :screen-light-shadow="theme.screenLightShadow"
    :screen-dark-shadow="theme.screenDarkShadow"
    :is-rounded="theme.isRounded">
    <div>
      <div id="wrap">
        <div id="main">
          CONTENT
        </div>
      </div>
      <navbar />
    </div>
  </vue-mobile-sized-view>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import theme from '@core/app-config/bank_theme'
import VueMobileSizedView from 'vue-mobile-sized-view';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import Navbar from './components/navbar.vue'

export default {
  components: {
    Navbar,
    VueMobileSizedView
  },
  data() {
    return {
      theme: theme,
      token: this.$route.query.token,
    }
  },
  mounted() {
  },
  methods: {
    login() {
      useJwt.setToken(this.token)
      useJwt.login()
        .then(response => {
          if (response.data.code !== 0) return this.$router.push({name: 'auth-login'})
          let userData = response.data.result.profile
          // useJwt.setToken(response.data.result.token)
          // useJwt.setRefreshToken(response.data.refreshToken)
          switch (userData.role) {
            case 'admin':
              userData.ability = [ { "action": "manage", "subject": "all" } ]
              break
            case 'system':
              userData.ability = [ { "action": "read", "subject": "page" }, { "action": "read", "subject": "credit" }, { "action": "read", "subject": "user" } ]
              break
            case 'employee':
              userData.ability = [ { "action": "read", "subject": "page" }, { "action": "read", "subject": "credit" } ]
              break
            default:
              userData.ability = [ { "action": "read", "subject": "Auth" } ]
          }
          localStorage.setItem('userData', JSON.stringify(userData))
          this.$ability.update(userData.ability)

          this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Welcome ${userData.displayName || userData.username}`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
                },
              })
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
</style>
